<template>
  <!-- <v-fade-transition mode="out-in"> -->
  <v-col
    v-if="formOpen"
    md="4"
    cols="12"
    :class="$vuetify.breakpoint.mdAndUp && 'pl-4'"
    id="form_"
  >
    <v-card
      class="py-6 pa-5 d-flex flex-column"
      :height="$vuetify.breakpoint.mdAndUp ? '659.7px' : '40rem'"
      ref="form_"
      style="overflow-y: auto"
    >
      <span style="height: 100%">
        <ValidationObserver v-slot="{ invalid }">
          <v-form
            @submit.prevent=""
            spellcheck="false"
            class="d-flex flex-column justify-space-between"
            style="height: 100%"
          >
            <span>
              <!-- Título y Close -->
              <v-row dense class="mb-6">
                <v-col cols="12" class="d-flex align-center">
                  <v-icon
                    medium
                    class="color-gray-text"
                    :style="handleColorClose"
                    @click.stop="handleClick()"
                  >
                    {{ icons.mdiWindowClose }}
                  </v-icon>
                  <h3 class="ml-6">
                    {{
                      createTripForm.tripType !== "Quick quote"
                        ? $t("Create a new Trip")
                        : $t("Quick quote")
                    }}
                  </h3>
                  <v-tooltip bottom :max-width="300">
                    <template #activator="{ on, attrs }">
                      <v-icon
                        medium
                        v-bind="attrs"
                        v-on="on"
                        class="color-gray-text ml-4"
                        :style="handleColorClose"
                      >
                        {{ icons.mdiInformationOutline }}
                      </v-icon>
                    </template>
                    <span v-if="formOpen">
                      <ul>
                        <li>
                          {{
                            $t(
                              "In this form you can create a new trip for now or scheduled"
                            )
                          }}
                        </li>
                        <li>
                          {{
                            $t(
                              "You can create trips for your corporate users or for third-party users"
                            )
                          }}
                        </li>
                        <li>
                          {{
                            $t(
                              "If you need to adjust coordinates for a trip you can use the source and destination pointers for that purpose"
                            )
                          }}
                        </li>
                        <li>
                          {{ $t("You can also make quick quotes") }}
                        </li>
                      </ul>
                    </span>
                  </v-tooltip>
                </v-col>
              </v-row>

              <!-- Formulario -->

              <!-- paso 1 -->
              <ValidationObserver>
                <template v-if="step === 1">
                  <!-- tipo de viaje -->
                  <v-row dense>
                    <!-- <code>{{ createTripForm.serviceType }}</code> -->
                    <v-col cols="12">
                      <ValidationProvider
                        :name="$t('Trip Type')"
                        rules="required"
                        v-slot="{ errors }"
                        slim
                      >
                        <v-select
                          :items="createTripForm.tripTypeOptions"
                          :label="$t('Trip Type')"
                          outlined
                          dense
                          v-model="createTripForm.tripType"
                          :error-messages="
                            errors[0] && [$t('Obligatory field')]
                          "
                        >
                          <!-- @change="createTripForm.serviceType = null" -->
                          <template v-slot:item="{ item }">
                            <div>
                              {{ $t(item) }}
                            </div>
                          </template>
                          <template v-slot:selection="{ item }">
                            <div>
                              {{ $t(item) }}
                            </div>
                          </template>
                        </v-select>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <!-- date picker -->
                  <v-row
                    dense
                    class="margin"
                    v-if="
                      createTripForm.tripType === 'Scheduled' ||
                      createTripForm.tripType === 'Quick quote'
                    "
                  >
                    <v-col cols="12">
                      <!-- <code>{{ dateTimePickerProps.datetime }}</code> -->

                      <ValidationProvider
                        :name="$t('Date and Time')"
                        rules="required"
                        v-slot="{ errors }"
                        slim
                      >
                        <v-datetime-picker
                          :label="$t('Date and Time')"
                          v-model="dateTimePickerProps.datetime"
                          dense
                          :text-field-props="dateTimePickerProps.textFieldProps"
                          :date-picker-props="dateTimePickerProps.dateProps"
                          :time-picker-props="dateTimePickerProps.timeProps"
                          time-format="h:mm a"
                          dateFormat="dd-MM-yyyy"
                          :error-messages="
                            errors[0] && [$t('Obligatory field')]
                          "
                        >
                          <template slot="dateIcon">
                            <v-icon>{{ icons.mdiCalendar }}</v-icon>
                          </template>
                          <template slot="timeIcon">
                            <v-icon>{{ icons.mdiClock }}</v-icon>
                          </template>
                          <template slot="actions" slot-scope="{ parent }">
                            <span
                              class="mt-5 d-flex flex-row justify-center"
                              style="width: 100%"
                            >
                              <v-btn
                                class="ml-3"
                                color="primary"
                                outlined
                                @click.native="parent.clearHandler"
                                >{{ $t("Clear") }}
                              </v-btn>
                              <v-btn
                                color="primary"
                                class="ml-4"
                                @click="parent.okHandler"
                                >{{ $t("Accept") }}
                              </v-btn>
                            </span>
                          </template>
                        </v-datetime-picker>
                      </ValidationProvider>
                      <!-- <code>{{dateTimePickerProps.datetime}}</code>
                  <v-text-field type="datetime-local" v-model="dateTimePickerProps.datetime" name="partydate" outlined dense></v-text-field> -->
                    </v-col>
                  </v-row>
                  <!-- punto de origen -->
                  <!-- <code>{{ createTripForm.originAddress }}</code> -->
                  <!-- <br /> -->
                  <!-- <code>{{ stateOriginAddressMap }}</code> -->
                  <v-row dense class="margin">
                    <v-col cols="12">
                      <ValidationProvider
                        :name="$t('Origin address')"
                        rules="required"
                        v-slot="{ errors }"
                        slim
                      >
                        <vuetify-google-autocomplete
                          id="origin"
                          classname="form-control"
                          :label="
                            createTripForm.originAddress &&
                            createTripForm.originAddress.length > 0 &&
                            $t('Origin address')
                          "
                          :placeholder="$t('Origin address')"
                          country="ve"
                          outlined
                          dense
                          clearable
                          :error-messages="
                            errors[0] && [$t('Obligatory field')]
                          "
                          v-model="createTripForm.originAddress"
                          @placechanged="setPlaceOrigin"
                          :enable-geolocation="true"
                          types=""
                        >
                          <!-- types="(locations)" -->
                          <template v-slot:prepend-inner>
                            <div>
                              <v-img
                                :src="
                                  require('../../../assets/IconOriginMap.png')
                                "
                              ></v-img>
                            </div>
                          </template>
                        </vuetify-google-autocomplete>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <!-- punto de destino -->
                  <!-- code -->
                  <v-row dense class="margin">
                    <v-col cols="12">
                      <ValidationProvider
                        :name="$t('Destination address')"
                        rules="required"
                        v-slot="{ errors }"
                        slim
                      >
                        <vuetify-google-autocomplete
                          id="destination"
                          classname="form-control"
                          :label="
                            createTripForm.destinationAddress &&
                            createTripForm.destinationAddress.length > 0 &&
                            $t('Destination address')
                          "
                          :placeholder="$t('Destination address')"
                          country="ve"
                          outlined
                          dense
                          clearable
                          :disabled="!stateOriginAddressMap"
                          :error-messages="
                            errors[0] && [$t('Obligatory field')]
                          "
                          enable-geolocation
                          v-model="createTripForm.destinationAddress"
                          @placechanged="setPlaceDestination"
                          types=""
                        >
                          <template v-slot:prepend-inner>
                            <div>
                              <v-img
                                :src="
                                  require('../../../assets/IconDestinationMap.png')
                                "
                              ></v-img>
                            </div>
                          </template>
                        </vuetify-google-autocomplete>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <!-- tipo de usuario -->
                  <v-row
                    dense
                    class="margin"
                    v-if="createTripForm.tripType !== 'Quick quote'"
                  >
                    <v-col cols="12">
                      <ValidationProvider
                        :name="$t('User type')"
                        rules="required"
                        v-slot="{ errors }"
                        slim
                      >
                        <v-select
                          :items="createTripForm.userTypeOptions"
                          :label="$t('User type')"
                          outlined
                          dense
                          v-model="createTripForm.userType"
                          :error-messages="
                            errors[0] && [$t('Obligatory field')]
                          "
                        >
                          <template v-slot:item="{ item }">
                            <div>
                              {{ $t(item) }}
                            </div>
                          </template>
                          <template v-slot:selection="{ item }">
                            <div>
                              {{ $t(item) }}
                            </div>
                          </template>
                        </v-select>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row
                    dense
                    class="margin"
                    v-if="
                      createTripForm.userType === 'Not corporate' &&
                      createTripForm.tripType !== 'Quick quote'
                    "
                  >
                    <v-col cols="5">
                      <ValidationProvider
                        :name="$t('Operator')"
                        rules="required"
                        v-slot="{ errors }"
                        v-if="createTripForm.userType === 'Not corporate'"
                        slim
                      >
                        <v-select
                          :items="createTripForm.phonePrefixList"
                          :label="$t('Operator')"
                          outlined
                          dense
                          v-model="createTripForm.phonePrefix"
                          :error-messages="
                            errors[0] && [$t('Obligatory field')]
                          "
                          v-if="createTripForm.userType === 'Not corporate'"
                          :disabled="loadingUserSearch"
                        >
                        </v-select>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="7">
                      <!-- <code>{{createTripForm.phone}}</code> -->
                      <ValidationProvider
                        :name="$t('Telephone')"
                        rules="required|min:7|max:7"
                        v-slot="{ errors }"
                        v-if="createTripForm.userType === 'Not corporate'"
                        slim
                      >
                        <PhoneInput
                          :label="$t('Telephone')"
                          v-model="createTripForm.phone"
                          dense
                          outlined
                          :long="false"
                          :error-messages="
                            errors[0] && [$t('Obligatory field')]
                          "
                          v-if="createTripForm.userType === 'Not corporate'"
                          :disabled="loadingUserSearch"
                          :loading="loadingUserSearch"
                        />
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <!-- Usuarios corporativos -->
                  <v-row
                    dense
                    class="margin"
                    v-if="createTripForm.tripType !== 'Quick quote'"
                  >
                    <v-col
                      :cols="createTripForm.userType === 'Corporate' ? 12 : 6"
                    >
                      <ValidationProvider
                        :name="$t('User name')"
                        rules="required"
                        v-slot="{ errors }"
                        v-if="createTripForm.userType === 'Corporate'"
                        slim
                      >
                        <v-autocomplete
                          v-if="createTripForm.userType === 'Corporate'"
                          v-model="createTripForm.userID"
                          :label="$t('User name')"
                          :items="createTripForm.corporateUsersSearch"
                          outlined
                          :error-messages="
                            errors[0] && [$t('Obligatory field')]
                          "
                          item-value="_id"
                          :menu-props="{ contentClass: 'list-style' }"
                          :filter="handleFilterAutocompleteCorporateUsers"
                          dense
                          @click="createTripForm.userID = null"
                        >
                          <template v-slot:selection="{ item }">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <!-- color="primary"
                              small
                              outlined -->
                                <v-avatar
                                  color="success"
                                  size="25"
                                  class="mr-1"
                                >
                                  <span
                                    class="d-flex align-center justify-center"
                                  >
                                    <v-img
                                      :src="`${bucketUrl}${item.picture}`"
                                      :alt="item.initials"
                                      width="25"
                                      v-if="item.picture"
                                    >
                                      <template v-slot:placeholder>
                                        <v-row
                                          class="fill-height ma-0"
                                          align="center"
                                          justify="center"
                                        >
                                          <v-progress-circular
                                            indeterminate
                                            color="grey lighten-5"
                                          ></v-progress-circular>
                                        </v-row> </template
                                    ></v-img>
                                    <span
                                      v-else
                                      class="color-blue-text text-caption"
                                    >
                                      {{ item.initials }}
                                    </span>
                                  </span>
                                </v-avatar>
                                <span v-bind="attrs" v-on="on">
                                  <span class="">
                                    {{ item.fullName }}
                                  </span>
                                </span>
                              </template>
                              <span class="d-flex flex-column">
                                <span class="d-flex flex-row align-center">
                                  <span class="ml-1">
                                    {{ item.phone }}
                                  </span>
                                  <v-icon
                                    x-small
                                    class="ml-1"
                                    style="color: #ffffff !important"
                                  >
                                    {{ icons.mdiPhoneOutline }}
                                  </v-icon>
                                  <span class="ml-1">|</span>
                                  <span class="ml-1">
                                    {{ item.email }}
                                  </span>
                                  <v-icon
                                    x-small
                                    class="ml-1"
                                    style="color: #ffffff !important"
                                  >
                                    {{ icons.mdiEmailOutline }}
                                  </v-icon>
                                </span>
                                <span class="mt-1">
                                  <span class="ml-1">
                                    <span class="font-weight-bold">
                                      {{ $t("user_wallet") }}:
                                    </span>
                                    {{ item.wallet | currency }}
                                  </span>
                                  <span class="ml-1">|</span>
                                  <span class="ml-1">
                                    <span class="font-weight-bold">
                                      {{ $t("Overdraft") }}:
                                    </span>
                                    {{ item.overdraft ? $t("Yes") : "No" }}
                                  </span>
                                </span>
                              </span>
                            </v-tooltip>
                          </template>
                          <template v-slot:item="{ item }">
                            <v-list-item-avatar color="#3BD4AE" size="45">
                              <span v-if="item.picture">
                                <v-img
                                  :src="`${bucketUrl}${item.picture}`"
                                  :alt="item.initials"
                                  width="45"
                                >
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height ma-0"
                                      align="center"
                                      justify="center"
                                    >
                                      <v-progress-circular
                                        indeterminate
                                        color="grey lighten-5"
                                      ></v-progress-circular>
                                    </v-row> </template
                                ></v-img>
                              </span>
                              <span v-else>
                                {{ item.initials }}
                              </span>
                            </v-list-item-avatar>
                            <br />
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ item.fullName }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                <span class="d-flex flex-column">
                                  <span>
                                    <span>
                                      {{ item.phone }}
                                    </span>
                                    <v-icon x-small class="ml-1">
                                      {{ icons.mdiPhoneOutline }}
                                    </v-icon>
                                    <span class="ml-1">|</span>
                                    <span class="ml-1">
                                      {{ item.email }}
                                    </span>
                                    <v-icon x-small class="ml-1">
                                      {{ icons.mdiEmailOutline }}
                                    </v-icon>
                                  </span>
                                  <span class="mt-1">
                                    <span class="ml-1">
                                      <span class="font-weight-bold">
                                        {{ $t("user_wallet") }}:
                                      </span>
                                      {{ item.wallet | currency }}
                                    </span>
                                    <span class="ml-1">|</span>
                                    <span class="ml-1">
                                      <span class="font-weight-bold">
                                        {{ $t("Overdraft") }}:
                                      </span>
                                      {{ item.overdraft ? $t("Yes") : "No" }}
                                    </span>
                                  </span>
                                </span>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                          <template v-slot:append-outer>
                            <div>
                              <v-tooltip top :max-width="200">
                                <template #activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on">
                                    {{ icons.mdiInformationOutline }}
                                  </v-icon>
                                </template>
                                <span>
                                  {{ $t("This input is autocomplete") }}
                                  <br />
                                  {{ $t("you may search corporate users by:") }}
                                  <ul class="mt-2">
                                    <li>
                                      {{ $t("fullName") }}
                                    </li>
                                    <li>
                                      {{ $t("Email") }}
                                    </li>
                                    <li>
                                      {{ $t("Phone") }}
                                    </li>
                                  </ul>
                                </span>
                              </v-tooltip>
                            </div>
                          </template>
                        </v-autocomplete>
                      </ValidationProvider>
                      <ValidationProvider
                        :name="$t('Name')"
                        rules="required|min:2|alpha_spaces"
                        v-slot="{ errors }"
                        v-if="createTripForm.userType === 'Not corporate'"
                        slim
                      >
                        <!-- autocomplete="new-password" para evitar sugerencias de chrome -->
                        <v-text-field
                          v-if="createTripForm.userType === 'Not corporate'"
                          v-model="createTripForm.userFirstName"
                          :label="$t('Name')"
                          outlined
                          dense
                          autocomplete="new-password"
                          :error-messages="
                            errors[0] && [$t('Obligatory field')]
                          "
                          :disabled="notCorporateDisabled"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-col
                      cols="6"
                      v-if="createTripForm.userType === 'Not corporate'"
                    >
                      <ValidationProvider
                        :name="$t('Last name')"
                        rules="required|min:2|alpha_spaces"
                        v-slot="{ errors }"
                        v-if="createTripForm.userType === 'Not corporate'"
                        slim
                      >
                        <v-text-field
                          v-model="createTripForm.userLastName"
                          v-if="createTripForm.userType === 'Not corporate'"
                          :label="$t('Last name')"
                          outlined
                          dense
                          autocomplete="new-password"
                          :error-messages="
                            errors[0] && [$t('Obligatory field')]
                          "
                          :disabled="notCorporateDisabled"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row
                    dense
                    class="margin"
                    v-if="
                      createTripForm.userType === 'Not corporate' &&
                      createTripForm.tripType !== 'Quick quote'
                    "
                  >
                    <v-col cols="12">
                      <ValidationProvider
                        :name="$t('Email')"
                        rules="required|email|max:100"
                        v-slot="{ errors }"
                        :debounce="450"
                        slim
                      >
                        <!-- :detectInput="false" -->
                        <v-text-field
                          v-model="createTripForm.email"
                          outlined
                          dense
                          :label="$t('Email')"
                          :placeholder="$t('Email')"
                          hide-details="auto"
                          class="mb-6"
                          autocapitalize="none"
                          autocomplete="new-password"
                          :error-messages="
                            errors[0] && [$t('Obligatory field')]
                          "
                          :disabled="notCorporateDisabledEmail"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <!-- Checkbox de saldo corporativo -->
                  <v-row
                    style="margin-top: -12px !important"
                    dense
                    class="margin"
                    v-if="
                      createTripForm.userType === 'Corporate' &&
                      createTripForm.userID &&
                      createTripForm.tripType !== 'Quick quote'
                    "
                  >
                    <v-col
                      cols="12"
                      class="d-flex flex-row justify-start ml-2 align-center"
                    >
                      <v-switch v-model="createTripForm.isCorporateWallet">
                        <template v-slot:label>
                          <span class="text-caption">
                            <span v-if="!createTripForm.isCorporateWallet">
                              Usar <u>saldo del usuario</u> para el viaje
                            </span>
                            <span v-else>
                              Usar <u>balance corporativo</u> para el viaje
                            </span>
                            <span>
                              <v-tooltip top :max-width="300">
                                <template #activator="{ on, attrs }">
                                  <v-icon
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                    class="color-gray-text"
                                    :style="handleColorClose"
                                  >
                                    {{ icons.mdiInformationOutline }}
                                  </v-icon>
                                </template>
                                <span v-if="createTripForm.isCorporateWallet">
                                  Al momento de crear el viaje para el usuario
                                  corporativo
                                  <b>{{
                                    computedCurrentCorporateUser.fullName
                                  }}</b>
                                  , el costo del mismo será debitado del
                                  <u>balance corporativo</u> ({{
                                    wallet | currency
                                  }}), no del <u>saldo</u> ({{
                                    computedCurrentCorporateUser.wallet
                                      | currency
                                  }})
                                  <span
                                    v-if="
                                      computedCurrentCorporateUser.overdraft
                                    "
                                  >
                                    ni <u>sobregiro</u>
                                  </span>
                                  de
                                  <b>{{
                                    computedCurrentCorporateUser.fullName
                                  }}</b>
                                </span>
                                <span v-else>
                                  Al momento de crear el viaje para el usuario
                                  corporativo
                                  <b>{{
                                    computedCurrentCorporateUser.fullName
                                  }}</b
                                  >, el costo del mismo será debitado de su
                                  <u>saldo</u> ({{
                                    computedCurrentCorporateUser.wallet
                                      | currency
                                  }})
                                  <span
                                    v-if="
                                      computedCurrentCorporateUser.overdraft
                                    "
                                  >
                                    o de su <u>sobregiro</u>
                                  </span>
                                </span>
                              </v-tooltip>
                            </span>
                          </span>
                        </template>
                      </v-switch>
                    </v-col>
                  </v-row>
                  <!-- tipo de servicio -->
                  <v-row dense class="margin">
                    <v-col cols="12">
                      <!-- <code>{{ createTripForm.serviceType }}</code>
                  <br />
                  <code>{{ Boolean(stateServiceTypeOrigin) }}</code> -->
                      <ValidationProvider
                        :name="$t('Service type')"
                        rules="required"
                        v-slot="{ errors }"
                        slim
                      >
                        <v-select
                          :items="createTripForm.serviceTypeOptions"
                          :label="$t('Service type')"
                          outlined
                          dense
                          v-model="createTripForm.serviceType"
                          :error-messages="
                            errors[0] && [$t('Obligatory field')]
                          "
                          :disabled="!stateOriginAddressMap"
                          item-text="type"
                          item-value="_id"
                          :menu-props="{ contentClass: 'list-style' }"
                          class="d-flex flex-row align-baseline"
                        >
                          <template
                            v-slot:append-outer
                            v-if="createTripForm.serviceType"
                          >
                            <v-tooltip top :max-width="300">
                              <template #activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  icon
                                  @click="getServiceTypeDrivers()"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon>
                                    {{ icons.mdiRefresh }}
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span>
                                <!-- <ul> -->
                                <!-- <li> -->
                                {{
                                  $t(
                                    "By clicking you can refresh the nearby providers in the map"
                                  )
                                }}
                                <!-- </li> -->
                                <!-- </ul> -->
                              </span>
                            </v-tooltip>
                          </template>
                          <template v-slot:selection="{ item }">
                            <div
                              class="
                                d-flex
                                flex-row
                                justify-space-between
                                font-weight-bold
                              "
                            >
                              <div class="d-flex flex-row align-center">
                                <v-img
                                  :src="`${bucketUrl}${item.image}`"
                                  max-width="45"
                                >
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height ma-0"
                                      align="center"
                                      justify="center"
                                    >
                                      <v-progress-circular
                                        indeterminate
                                        color="grey lighten-5"
                                      ></v-progress-circular>
                                    </v-row> </template
                                ></v-img>
                                <h4 class="fontUrbanist ml-2">
                                  {{ item.type }}
                                </h4>
                                <span class="d-flex align-center ml-2">
                                  <v-icon x-small>
                                    {{ icons.mdiAccount }}
                                  </v-icon>
                                  <span class="ml-1">
                                    {{ item.capacity }}
                                  </span>
                                  <span class="ml-1">|</span>
                                  <v-icon x-small class="ml-1">
                                    {{ icons.mdiBagSuitcase }}
                                  </v-icon>
                                  <span class="ml-1">
                                    {{ item.baggage }}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </template>
                          <template v-slot:item="{ item }">
                            <v-img
                              :src="`${bucketUrl}${item.image}`"
                              max-width="60"
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular
                                    indeterminate
                                    color="grey lighten-5"
                                  ></v-progress-circular>
                                </v-row> </template
                            ></v-img>
                            <v-list-item-content>
                              <v-list-item-title class="fontUrbanist ml-1">
                                <h4>
                                  {{ item.type }}
                                </h4>
                              </v-list-item-title>
                              <v-list-item-subtitle class="ml-1">
                                <span class="d-flex align-center">
                                  <v-icon x-small>
                                    {{ icons.mdiAccount }}
                                  </v-icon>
                                  <span class="ml-1">
                                    {{ item.capacity }}
                                  </span>
                                  <span class="ml-1">|</span>
                                  <v-icon x-small class="ml-1">
                                    {{ icons.mdiBagSuitcase }}
                                  </v-icon>
                                  <span class="ml-1">
                                    {{ item.baggage }}
                                  </span>
                                </span>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </v-select>
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <!-- descripcion -->
                  <v-row dense class="margin">
                    <v-col cols="12">
                      <ValidationProvider
                        :name="$t('trip description')"
                        rules="max:500"
                        v-slot="{ errors }"
                        slim
                      >
                        <v-text-field
                          v-model="createTripForm.tripComment"
                          :label="$t('trip description')"
                          outlined
                          dense
                          :error-messages="errors[0]"
                          maxlength="60"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </template>
              </ValidationObserver>

              <!-- paso 2 -->
              <ValidationObserver>
                <template v-if="step === 2">
                  <template>
                    <v-row dense>
                      <v-col cols="12">
                        <p class="text--primary shipment-summary">
                          {{ $t("trip.trip_resume") }}
                        </p>
                      </v-col>
                    </v-row>
                    <v-row dense class="border-bottom-gray">
                      <v-col
                        cols="12"
                        class="d-flex flex-column justify-center align-center"
                      >
                        <span
                          class="text--primary fontUrbanist font-weight-bold"
                          >{{ demoQuote["Tipo de servicio"].typename }}</span
                        >
                        <v-img
                          :src="`${bucketUrl}${demoQuote['Tipo de servicio'].type_image_url}`"
                          max-width="70"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                              ></v-progress-circular>
                            </v-row> </template
                        ></v-img>
                      </v-col>
                    </v-row>
                    <!-- origen quote -->
                    <v-row
                      dense
                      class="
                        d-flex
                        flex-row
                        align-center
                        justify-center
                        text-sm
                        border-bottom-gray
                        mt-2
                      "
                      ><v-col
                        cols="1"
                        class="d-flex flex-row align-center justify-center"
                      >
                        <v-img
                          :src="require('../../../assets/IconOriginMapMap.png')"
                          contain
                          max-width="40"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-col>
                      <v-col
                        cols="11"
                        class="d-flex flex-column align-start justify-center"
                      >
                        <!-- origen -->
                        <span class="text-justify">
                          <span class="font-weight-bold success--text"
                            >{{ $t("parcel_quote_origin") }}:</span
                          >
                          <v-tooltip top max-width="300" :disabled="!false">
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                class="ml-1 text-justify"
                                v-bind="attrs"
                                v-on="on"
                                >{{
                                  truncator(
                                    demoQuote["Dirección de origen"],
                                    300
                                  )
                                }}</span
                              >
                            </template>
                            <span>{{ demoQuote["Dirección de origen"] }}</span>
                          </v-tooltip>
                        </span>
                      </v-col>
                    </v-row>
                    <!-- destination quote -->
                    <v-row
                      dense
                      class="
                        d-flex
                        flex-row
                        align-center
                        justify-center
                        text-sm
                        border-bottom-gray
                        mt-2
                      "
                      ><v-col
                        cols="1"
                        class="d-flex flex-row align-center justify-center"
                      >
                        <v-img
                          :src="
                            require('../../../assets/IconDestinationMapMap.png')
                          "
                          contain
                          max-width="40"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-col>
                      <v-col
                        cols="11"
                        class="d-flex flex-column align-start justify-center"
                      >
                        <!-- origen -->
                        <span class="text-justify">
                          <span class="font-weight-bold error--text"
                            >{{ $t("parcel_quote_destination") }}:</span
                          >
                          <v-tooltip top max-width="300" :disabled="!false">
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                class="ml-1 text-justify"
                                v-bind="attrs"
                                v-on="on"
                                >{{
                                  truncator(
                                    demoQuote["Dirección de destino"],
                                    300
                                  )
                                }}</span
                              >
                            </template>
                            <span>{{ demoQuote["Dirección de destino"] }}</span>
                          </v-tooltip>
                        </span>
                      </v-col>
                    </v-row>
                    <!-- total a pagar y surge -->
                    <v-row dense class="mt-2 text-sm mb-6">
                      <v-col cols="12">
                        <span class="font-weight-bold primary--text">
                          {{ $t("Trip Type") }}:
                        </span>
                        <span> {{ $t(createTripForm.tripType) }} </span>
                      </v-col>
                      <v-col cols="12">
                        <span class="font-weight-bold primary--text">
                          {{ $t("Date and Time") }}:
                        </span>
                        <span> {{ demoQuote["Fecha y hora"] }}</span>
                      </v-col>
                      <v-col
                        cols="12"
                        v-if="createTripForm.tripType !== 'Quick quote'"
                      >
                        <span class="font-weight-bold primary--text">
                          {{ $t("User type") }}:
                        </span>
                        <span> {{ $t(demoQuote["Tipo de usuario"]) }}</span>
                      </v-col>
                      <v-col
                        cols="12"
                        v-if="createTripForm.tripType !== 'Quick quote'"
                      >
                        <span class="font-weight-bold primary--text">
                          {{ $t("Nombre y apellido") }}:
                        </span>
                        <span>
                          {{ demoQuote["Nombre y apellido"] }}
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                small
                                class="mb-1"
                                v-bind="attrs"
                                v-on="on"
                                >{{ icons.mdiInformationOutline }}</v-icon
                              >
                            </template>
                            <span>{{ $t("person_is_contact") }}</span>
                          </v-tooltip></span
                        >
                      </v-col>
                      <v-col cols="12" class="mb-2">
                        <span class="font-weight-bold primary--text">
                          {{ $t("Sobredemanda") }}:
                        </span>
                        <span> {{ demoQuote["Sobredemanda"] }} </span>
                      </v-col>
                      <v-col
                        cols="6"
                        class="d-flex flex-row justify-center align-center"
                      >
                        <span>
                          <v-icon color="primary">
                            {{ icons.mdiMapMarkerDistance }}
                          </v-icon>
                        </span>
                        <span class="ml-1"> {{ demoQuote["DISTANCIA"] }} </span>
                      </v-col>
                      <v-col
                        cols="6"
                        class="d-flex flex-row justify-center align-center"
                      >
                        <span>
                          <v-icon color="primary">
                            {{ icons.mdiClockOutline }}
                          </v-icon>
                        </span>
                        <span class="ml-1"> {{ demoQuote["DURACIÓN"] }} </span>
                      </v-col>
                    </v-row>
                  </template>
                </template>
              </ValidationObserver>
            </span>

            <!-- Botones -->
            <span class="pb-3">
              <v-row dense>
                <v-col cols="12">
                  <template v-if="step === 1">
                    <v-btn
                      v-if="createTripForm.tripType === 'Quick quote'"
                      color="primary"
                      block
                      @click="handleStep1()"
                      :disabled="
                        invalid || !Boolean(stateDestinationAddressMap)
                      "
                    >
                      {{ $t("Get quick quote") }}
                    </v-btn>
                    <v-btn
                      v-else
                      color="primary"
                      block
                      @click="handleStep1()"
                      :disabled="
                        invalid ||
                        (createTripForm.tripType === 'Travel now'
                          ? !Boolean(stateServiceTypeOrigin) ||
                            !Boolean(stateDestinationAddressMap)
                          : !Boolean(stateDestinationAddressMap))
                      "
                    >
                      {{ $t("Get quote") }}
                    </v-btn>
                  </template>
                  <template v-if="step === 2">
                    <span
                      class="color-red-text"
                      :class="
                        $vuetify.breakpoint.lgAndUp
                          ? 'text-body-2'
                          : 'text-caption'
                      "
                      v-if="createTripForm.tripType !== 'Quick quote'"
                    >
                      <!-- {{ wallet | currency }}
                    <br />
                    {{ overdraftUser }}
                    <br />
                    {{ corporateWalletUser }}
                    <br /> -->
                      <span
                        class="font-weight-bold"
                        v-if="computedDisabledCreateTripNote"
                        >Nota:
                      </span>
                      <span
                        v-if="computedDisabledCreateTripNote"
                        v-html="computedDisabledCreateTripNote"
                      >
                      </span>
                    </span>
                    <div
                      class="
                        background-green
                        color-blue-text
                        pa-2
                        font-weight-bold
                        d-flex
                        justify-space-between
                      "
                    >
                      <span
                        >TOTAL<span>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on"> * </span>
                            </template>
                            <span>{{ $t("variable_tariffs") }}</span>
                          </v-tooltip></span
                        >:</span
                      >
                      <div>
                        <span
                          :class="
                            demoQuote['total_without_promo']
                              ? 'text-decoration-line-through error--text mr-2'
                              : ''
                          "
                        >
                          {{ demoQuote["total_without_promo"] }}
                        </span>
                        <span>{{ demoQuote["TOTAL"] }}</span>
                      </div>
                    </div>
                    <v-row dense class="margin mt-4">
                      <v-col
                        cols="12"
                        v-if="createTripForm.userType === 'Corporate'"
                      >
                        <ValidationProvider
                          :name="$t('Promo Code')"
                          rules="max:500"
                          v-slot="{ errors }"
                          slim
                        >
                          <v-text-field
                            v-model="createTripForm.promo_code"
                            outlined
                            dense
                            :label="$t('Promo Code')"
                            :placeholder="$t('Add Promo Code')"
                            :error-messages="errors[0]"
                            maxlength="60"
                            :readonly="isPromoCodeValid || loading_promo"
                            @keyup.enter.prevent.stop="handlePromoCodeClick"
                          >
                            <template v-slot:append>
                              <div
                                class="d-flex align-center cursor-pointer pb-2"
                              >
                                <v-btn icon @click="handlePromoCodeClick">
                                  <v-progress-circular
                                    v-if="loading_promo"
                                    indeterminate
                                  />
                                  <v-icon v-else>
                                    {{
                                      isPromoCodeValid
                                        ? icons.mdiCloseCircle
                                        : icons.mdiPlus
                                    }}
                                  </v-icon>
                                </v-btn>
                                <span
                                  v-if="!loading_promo"
                                  @click="handlePromoCodeClick"
                                >
                                  {{
                                    isPromoCodeValid ? $t("Remove") : $t("Add")
                                  }}
                                </span>
                              </div>
                            </template>
                          </v-text-field>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-btn
                      v-if="createTripForm.tripType === 'Quick quote'"
                      color="primary"
                      block
                      @click="downloadInvoice()"
                      class="mt-4"
                    >
                      {{ $t("Download Quote") }}
                    </v-btn>
                    <v-btn
                      color="primary"
                      outlined
                      block
                      @click="handleStepBack()"
                    >
                      {{ $t("GoBack") }}
                    </v-btn>
                    <v-btn
                      color="primary"
                      class="mt-2"
                      block
                      @click="handleCreate()"
                      v-if="createTripForm.tripType !== 'Quick quote'"
                      :disabled="computedDisabledCreateTrip"
                    >
                      {{ $t("Create trip") }}
                    </v-btn>
                  </template>
                </v-col>
              </v-row>
            </span>
          </v-form>
        </ValidationObserver>
      </span>
    </v-card>
  </v-col>
  <!-- </v-fade-transition> -->
</template>
<script>
import {
  mdiWindowClose,
  mdiMapMarker,
  mdiCalendar,
  mdiClock,
  mdiPhoneOutline,
  mdiEmailOutline,
  mdiAccount,
  mdiBagSuitcase,
  mdiRefresh,
  mdiInformationOutline,
  mdiMapMarkerDistance,
  mdiClockOutline,
  mdiPlus,
  mdiCloseCircle,
} from "@mdi/js";
import DateTimePicker from "vuetify-datetime-picker";
import { mapActions, mapState } from "vuex";
import { gmapApi } from "vue2-google-maps";
import PhoneInput from "@/components/PhoneInput";
import axios from "@/plugins/axios";
import Modal from "./Modal.vue";
import ModalEN from "./ModalEN.vue";
import errorCode from "@/api/errorCode";
import { truncator } from "@/utils";
import {
  getRouteDistanceAndDurationOpenStreet,
  getRouteDistanceAndDurationGoogleMaps,
} from "@/api/timeDistanceCalculation";

export default {
  components: { DateTimePicker, PhoneInput },
  props: {
    formOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading_promo: false,
      isPromoCodeValid: false,
      promo_id: null,
      errorCode: errorCode,
      icons: {
        mdiWindowClose,
        mdiMapMarker,
        mdiCalendar,
        mdiClock,
        mdiPhoneOutline,
        mdiEmailOutline,
        mdiAccount,
        mdiBagSuitcase,
        mdiRefresh,
        mdiInformationOutline,
        mdiMapMarkerDistance,
        mdiClockOutline,
        mdiPlus,
        mdiCloseCircle,
      },
      createTripForm: {
        tripType: "Travel now",
        tripTypeOptions: ["Travel now", "Scheduled", "Quick quote"],
        originAddress: null,
        destinationAddress: null,
        userType: null,
        userTypeOptions: ["Corporate", "Not corporate"],
        serviceType: null,
        serviceTypeOptions: [],
        userID: null,
        userFirstName: null,
        userLastName: null,
        phonePrefixList: ["0412", "0414", "0424", "0416", "0426"],
        phonePrefix: null,
        phone: null,
        email: null,
        originAddressMap: null,
        destinationAddressMap: null,
        corporateUsers: [],
        isCorporateWallet: false,
        tripComment: null,
      },
      dateTimePickerProps: {
        nullDatetime: null,
        datetime: new Date(),
        datetimeString: "2019-01-01 12:00",
        formattedDatetime: "09/01/2019 12:00",
        textFieldProps: {
          appendIcon: "mdi-event",
          dense: true,
          outlined: true,
        },
        dateProps: {
          // headerColor: "red",
          min: "",
          max: "",
        },
        timeProps: {
          format: "ampm",
        },
      },
      step: 1,
      demoQuote: {
        // "Tipo de viaje": "Programado",
        // "Fecha y hora": "17/03/2022 05:12 PM",
        // "Dirección de origen":
        //   "Calle París, Caracas, Distrito Capital, Venezuela",
        // "Dirección de destino": "Metrobus La Salle, Caracas 1050",
        // "Tipo de usuario": "Corporativo",
        // "Nombre y apellido": "Carlos Perez",
        // "Tipo de servicio": "Carro",
        // DISTANCIA: "5.6 KM",
        // DURACIÓN: "12 min",
        // TOTAL: "$5",
      },
      address: null,
      distance: null,
      bucketUrl: process.env.VUE_APP_IMAGE_BUCKET,
      serviceTypeIdName: {},
      estimatedFare: "",
      estimatedFareWithoutPromo: "",
      surgeMultiplier: "",
      isSurgeHours: "",
      city: "",
      scheduleDate: "",
      overdraftUser: null,
      corporateWalletUser: null,
      notCorporateDisabled: true,
      notCorporateDisabledEmail: true,
      corporateUsersSearch: [],
      loadingUserSearch: false,
      notCorporateDisabledNameFields: true,
      emailValidated: false,
      zonePrice: {
        isZoneRechargeable: false,
        zonePickupID: "",
      },
      userFoundInRideryDB: false,
    };
  },
  methods: {
    truncator,
    getRouteDistanceAndDurationOpenStreet,
    getRouteDistanceAndDurationGoogleMaps,
    ...mapActions([
      "setLoading",
      "setOrigin",
      "setDestination",
      "setServiceTypeOrigin",
      "setDraggable",
    ]),
    ...mapActions("auth", ["meNotLoading"]),

    handleErrorCoder(object, val) {
      return Object.keys(object).find((key) => object[key] === `${val}`);
    },
    handleClick() {
      this.$emit("emitClick");
      this.step = 1;
      this.resetForm();
    },
    handleStepBack() {
      this.step--;
      this.setDraggable(true);
    },
    async handleStep1() {
      this.setLoading(true);
      await this.meNotLoading();
      try {
        const goodDistanceTripCalculation = await this.getDistanceTrip();
        if (goodDistanceTripCalculation) {
          await this.getQuote();
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      } finally {
        this.setLoading(false);
      }
    },
    async getQuote() {
      try {
        const { data } = await axios.post(
          `${process.env.VUE_APP_API_URL}/fare_estimate`,
          {
            latitude: `${this.stateOriginAddressMap.geometry.location.lat()}`,
            longitude: `${this.stateOriginAddressMap.geometry.location.lng()}`,
            dest_latitude: `${this.stateDestinationAddressMap.geometry.location.lat()}`,
            dest_longitude: `${this.stateDestinationAddressMap.geometry.location.lng()}`,
            distance: this.distance,
            duration: this.duration,
            service_type_id:
              this.stateServiceTypeOrigin && this.stateServiceTypeOrigin[0].type
                ? this.stateServiceTypeOrigin[0].type
                : this.createTripForm.serviceType,
            time: this.computedDateQuoteFareEstimate,
            timezone: this.computedTimezone,
          }
        );
        if (!!data) {
          if (this.createTripForm.tripType === "Quick quote") {
            var quote = {
              "Tipo de viaje": this.createTripForm.tripType,
              "Fecha y hora": this.$moment(this.computedDateQuote)
                .locale("es")
                .format("DD-MM-YYYY h:mm a"),
              "Dirección de origen":
                this.stateOriginAddressMap.formatted_address,
              "Dirección de destino":
                this.stateDestinationAddressMap.formatted_address,
              "Tipo de servicio":
                this.serviceTypeIdName[this.createTripForm.serviceType],
              Sobredemanda: Boolean(data.is_surge_hours)
                ? `${data.surge_multiplier}x`
                : "No",
              DISTANCIA: data.distance + "Km",
              DURACIÓN: Math.ceil(data.time) + "m",
              TOTAL: "$" + data.estimated_fare,
            };
          } else {
            var quote = {
              "Tipo de viaje": this.createTripForm.tripType,
              "Dirección de origen":
                this.stateOriginAddressMap.formatted_address,
              "Dirección de destino":
                this.stateDestinationAddressMap.formatted_address,
              "Tipo de usuario": this.createTripForm.userType,
              "Nombre y apellido":
                this.createTripForm.userType === "Corporate"
                  ? this.createTripForm.corporateUsersSearch.find(
                      (u) => u._id === this.createTripForm.userID
                    ).fullName +
                    " | " +
                    this.createTripForm.corporateUsersSearch.find(
                      (u) => u._id === this.createTripForm.userID
                    ).phone
                  : this.createTripForm.userFirstName +
                    " " +
                    this.createTripForm.userLastName +
                    " | " +
                    this.createTripForm.phonePrefix +
                    this.createTripForm.phone,
              "Tipo de servicio":
                this.serviceTypeIdName[this.createTripForm.serviceType],
              Sobredemanda: Boolean(data.is_surge_hours)
                ? `${data.surge_multiplier}x`
                : "No",
              DISTANCIA: data.distance + "Km",
              DURACIÓN: Math.ceil(data.time) + "m",
              TOTAL: "$" + data.estimated_fare,
            };
          }
          this.overdraftUser =
            this.createTripForm.userType === "Corporate" &&
            this.createTripForm.corporateUsersSearch.find(
              (u) => u._id === this.createTripForm.userID
            ).overdraft;
          this.corporateWalletUser =
            this.createTripForm.userType === "Corporate" &&
            this.createTripForm.corporateUsersSearch.find(
              (u) => u._id === this.createTripForm.userID
            ).wallet;
          this.estimatedFare = data.estimated_fare;
          this.estimatedFareWithoutPromo = data.estimated_fare;
          this.surgeMultiplier = data.surge_multiplier;
          this.isSurgeHours = data.is_surge_hours;
          this.demoQuote = quote;
          this.demoQuote["Fecha y hora"] = this.$moment(this.computedDateQuote)
            .locale("es")
            .format("DD/MM/YYYY HH:mm");
          this.step++;
          this.$emit("updateQuote", {
            ...quote,
            "Fecha y hora": this.$moment(this.computedDateQuote)
              .locale("es")
              .format("DD/MM/YYYY HH:mm"),
          });
          this.setDraggable(false);

          if (this.promo_id) {
            this.validatePromoCode();
          }
        }
      } catch (error) {
        console.log("error", error);
        this.$dialog.notify.error("Quote error");
      }
    },
    async handleCreate() {
      if (this.createTripForm.userType === "Corporate") {
        this.handleCheck(this.createTripForm.userID);
      }
      if (this.createTripForm.userType === "Not corporate") {
        this.handleCheck();
      }
    },
    async handleCheck(id) {
      //corporate
      this.setLoading(true);
      try {
        let params = {};
        if (id) {
          params["user_id"] = id;
        }
        if (!id) {
          params = {
            user_id: "",
            first_name: this.createTripForm.userFirstName,
            last_name: this.createTripForm.userLastName,
            email: this.createTripForm.email,
            is_new_user: this.userFoundInRideryDB ? 0 : 1,
            phone:
              this.createTripForm.phonePrefix.substring(1) +
              this.createTripForm.phone,
            country_phone_code: this.countryPhoneCode,
            city: this.city, //ajustar con respuesta de service type
            country: this.countryName,
          };
        }
        //validamos usuario
        const { data } = await axios.post(
          `${process.env.VUE_APP_STAGING}/checkuser_corporate`,
          params
        );
        if (data.success) {
          if (!id) {
            this.createTripForm.userID = data.user._id;
          }
          if (!!data.success) {
            let user_id_readable = data.user.unique_id;
            //intentamos crear el viaje
            try {
              let params_create = {
                is_corporate_wallet:
                  this.createTripForm.userType === "Corporate" &&
                  this.createTripForm.isCorporateWallet,
                is_panel_new: 1,
                user_id: this.createTripForm.userID,
                service_type_id: this.createTripForm.serviceType,
                timezone: "America/Caracas", //ajustar
                latitude: `${this.stateOriginAddressMap.geometry.location.lat()}`,
                longitude: `${this.stateOriginAddressMap.geometry.location.lng()}`,
                fixed_price: parseFloat(this.estimatedFare),
                trip_type: 7,
                user_type_id: this._id,
                distance: this.distance,
                time: this.duration,
                source_address: this.stateOriginAddressMap.formatted_address,
                destination_address:
                  this.stateDestinationAddressMap.formatted_address,
                d_latitude: `${this.stateDestinationAddressMap.geometry.location.lat()}`,
                d_longitude: `${this.stateDestinationAddressMap.geometry.location.lng()}`,
                is_panel_trip: 1,
                surge_multiplier: this.surgeMultiplier,
                is_surge_hours: this.isSurgeHours,
                is_fixed_fare: 1,
                payment_mode: 10,
                is_scheduled_trip:
                  this.createTripForm.tripType === "Scheduled" ? 1 : 0,
                start_time:
                  this.createTripForm.tripType === "Scheduled"
                    ? Math.round(
                        new Date(this.dateTimePickerProps.datetime).getTime() -
                          new Date().getTime()
                      )
                    : undefined,
                promo_id: this.promo_id ?? undefined,
                trip_comment: this.createTripForm.tripComment,
              };
              const { data } = await axios.post(
                `${process.env.VUE_APP_STAGING}/createtrip`,
                params_create
              );
              if (!!data) {
                if (data.success) {
                  this.$emit("tripCreated");
                  this.$emit("emitClick");
                  this.$dialog.notify.success(
                    this.$t("Trip created successfully")
                  );
                  this.resetForm();
                } else {
                  if (data.error_code) {
                    let key = this.handleErrorCoder(
                      this.errorCode,
                      data.error_code
                    );
                    throw new Error(
                      `Error ${data.error_code}: ${key}, IDCORP:${this.unique_id}, IDUSER: ${user_id_readable}`
                    );
                  } else {
                    throw new Error(
                      `Error in Trip Creation, IDCORP:${this.unique_id} , IDUSER: ${user_id_readable}`
                    );
                  }
                }
              }
            } catch (error) {
              this.$dialog.notify.error(error.message);
            }
          }
        }
        if (!data.success) {
          if (id) {
            throw new Error(
              `Error checkuser: Corporate IDUSER: ${data.user.unique_id}, IDCORP: ${this.unique_id}`,
              data.trip
                ? this.handleErrorCauseCheckUser(data)
                : {
                    cause: {
                      properCause: "ULTIMO_VIAJE_PENDIENTE_POR_CALIFICACION",
                    },
                  }
            );
          } else {
            throw new Error(
              `Error checkuser: Not Corporate IDUSER: ${data.user.unique_id}, IDCORP: ${this.unique_id}`,
              data.trip
                ? this.handleErrorCauseCheckUser(data)
                : {
                    cause: {
                      properCause: "ULTIMO_VIAJE_PENDIENTE_POR_CALIFICACION",
                    },
                  }
            );
          }
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
        error.cause?.tripID
          ? this.$dialog.notify.error(
              `IDTRIP: ${error.cause.tripID || "N/A"}, ${
                error.cause.properCause
              }`
            )
          : error.cause
          ? this.$dialog.notify.error(`${error.cause?.properCause}`)
          : null;
      }
      this.setLoading(false);
    },
    resetForm() {
      this.step = 1;

      // datos user
      this.createTripForm.tripType = "Travel now";
      this.createTripForm.userType = null;
      this.createTripForm.serviceType = null;
      this.createTripForm.userID = null;
      this.createTripForm.userFirstName = null;
      this.createTripForm.userLastName = null;
      this.createTripForm.phonePrefix = null;
      this.createTripForm.phone = null;
      this.createTripForm.email = null;
      this.corporateUsers = [];
      this.createTripForm.isCorporateWallet = false;

      // datos mapa
      this.createTripForm.originAddress = null;
      this.createTripForm.originAddressMap = null;
      this.createTripForm.destinationAddress = null;
      this.createTripForm.destinationAddressMap = null;
      this.address = null;
      this.setOrigin(null);
      this.setDestination(null);
      this.setServiceTypeOrigin(null);
      this.distance = null;
      this.duration = null;
      this.setDraggable(true);

      //hora
      this.dateTimePickerProps.datetime = null;

      // promo code
      this.promo_id = null;
      this.isPromoCodeValid = false;
      this.loading_promo = false;
      this.createTripForm.promo_code = null;
      // comentario
      this.createTripForm.tripComment = null;
    },
    geoCodeLocationOrigin(place) {
      let self = this;
      let geocoder = new this.google.maps.Geocoder();
      geocoder.geocode({ placeId: place.place_id }, async (results, status) => {
        try {
          self.setLoading(true);
          if (status == (await google.maps.GeocoderStatus.OK)) {
            let address = results[0];
            !!address && (self.address = address);
            if (self.address) {
              self.address.formatted_address =
                place.name + ", " + self.address.formatted_address;
              self.setOrigin(self.address);
              self.createTripForm.originAddressMap = self.address;
              self.address = null;
            }
          }
          self.setLoading(false);
        } catch (error) {
          console.log(error);
        }
      });
    },
    async geoCodeLocationDestination(place) {
      let self = this;
      let geocoder = new this.google.maps.Geocoder();
      await geocoder.geocode({ placeId: place.place_id }, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          let address = results[0];
          !!address && (self.address = address);
          if (self.address) {
            self.address.formatted_address =
              place.name + ", " + self.address.formatted_address;
            self.setDestination(self.address);
            self.createTripForm.destinationAddressMap = self.address;
            self.address = null;
          }
        } else {
          console.log(status);
        }
      });
    },
    setPlaceOrigin(place) {
      if (!!place) {
        this.geoCodeLocationOrigin(place);
      }
    },
    async setPlaceDestination(place) {
      if (!!place) {
        this.setLoading(true);
        await this.geoCodeLocationDestination(place);
        this.setLoading(false);
      }
    },
    async getServiceType() {
      this.setLoading(true);
      try {
        const { data } = await axios.post(
          `${process.env.VUE_APP_STAGING}/typelist_for_dispatcher`,
          {
            subAdminCountry: this.countryName,
            latitude: `${this.stateOriginAddressMap.geometry.location.lat()}`,
            longitude: `${this.stateOriginAddressMap.geometry.location.lng()}`,
          },
          {
            params: {
              only_shipments: 0,
            },
          }
        );
        if (!!data.success) {
          this.createTripForm.serviceTypeOptions = [];
          data.citytypes.forEach((type) => {
            this.createTripForm.serviceTypeOptions.push({
              type: type.type_details.typename,
              image: type.type_details.type_image_url,
              baggage: type.baggages,
              capacity: type.max_space,
              _id: type._id,
              sequence_no: type.type_details.sequence_no,
            });
          });
          this.createTripForm.serviceTypeOptions.sort(
            (a, b) => a.sequence_no - b.sequence_no
          );
          data.citytypes.forEach((type) => {
            this.serviceTypeIdName[`${type._id}`] = type.type_details;
          });
          this.country_id = data.city_detail.countryid;
          this.city_id = data.city_detail._id;
          this.city = data.city_detail.cityname;
        } else {
          this.createTripForm.serviceTypeOptions = [];
          if (data.error_code === "1002") {
            this.setServiceTypeOrigin(null);
            throw new Error(
              this.$t(
                "Lo sentimos, Ridery no presta servicio en esa dirección de origen"
              )
            );
          }
          throw data;
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
      this.setLoading(false);
    },
    async getCorporateUsers() {
      this.setLoading(true);
      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_URL}/users`
        );
        if (!!data) {
          this.createTripForm.corporateUsersSearch = [];
          data.user_list.forEach((user) => {
            Boolean(user.is_approved) &&
              this.createTripForm.corporateUsersSearch.push({
                fullName: `${user.first_name} ${user.last_name}`,
                _id: user._id,
                phone: user.phone,
                initials: user.first_name[0] + user.last_name[0],
                email: user.email,
                overdraft: user.is_allow_overdraft,
                wallet: user.corporate_wallet_limit,
                picture: user.picture,
                token: user.token,
              });
          });
          this.createTripForm.corporateUsersSearch.sort((a, b) => {
            return a.fullName.localeCompare(b.fullName);
          });
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
      this.setLoading(false);
    },
    async getDistanceTrip() {
      this.distance = null;
      this.duration = null;
      const origin = {
        lat: parseFloat(this.stateOriginAddressMap.geometry.location.lat()),
        lng: parseFloat(this.stateOriginAddressMap.geometry.location.lng()),
      };
      const destination = {
        lat: parseFloat(
          this.stateDestinationAddressMap.geometry.location.lat()
        ),
        lng: parseFloat(
          this.stateDestinationAddressMap.geometry.location.lng()
        ),
      };
      try {
        if (this.is_used_google_maps_service) {
          const { distance, duration } =
            await getRouteDistanceAndDurationGoogleMaps(
              origin,
              destination,
              this.google
            );
          this.distance = distance;
          this.duration = duration;
          return true;
        } else {
          const { distance, duration } =
            await getRouteDistanceAndDurationOpenStreet(origin, destination);
          this.distance = distance;
          this.duration = duration;
          return true;
        }
      } catch (error) {
        console.info(error);
        this.$dialog.notify.error(error.message);
        return false;
      }
    },
    async getServiceTypeDrivers() {
      let origin = {
        lat: parseFloat(this.stateOriginAddressMap.geometry.location.lat()),
        lng: parseFloat(this.stateOriginAddressMap.geometry.location.lng()),
      };
      this.setLoading(true);
      try {
        const { data } = await axios.post(
          `${process.env.VUE_APP_STAGING}/get_nearby_provider`,
          {
            service_type_id: this.createTripForm.serviceType,
            latitude: origin.lat,
            longitude: origin.lng,
          }
        );
        if (!!data.success) {
          let providers_ = [];
          data.providers.forEach((provider) => {
            provider.service_type.forEach((type) => {
              if (type === this.createTripForm.serviceType) {
                providers_.push({
                  type: type,
                  bearing: provider.bearing,
                  picture: provider.picture,
                  lat: parseFloat(provider.providerLocation[0]),
                  lng: parseFloat(provider.providerLocation[1]),
                });
              }
            });
          });
          this.setServiceTypeOrigin(providers_);
        } else {
          if (this.createTripForm.tripType === "Travel now") {
            this.setServiceTypeOrigin(null);
            this.showModal();
          }
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
      this.setLoading(false);
    },
    async showModal() {
      const params = {
        fullscreen: false,
        width: !this.$vuetify.breakpoint.mdAndUp ? "80%" : "40%",
        transition: "dialog-top-transition",
      };

      if (this.$vuetify.lang.current === "es") {
        await this.$dialog.showAndWait(Modal, params);
      } else {
        await this.$dialog.showAndWait(ModalEN, params);
      }
    },
    capitalizeFirstLetter: (str) => {
      return str ? str[0].toUpperCase() + str.slice(1) : "";
    },
    handleFilterAutocompleteCorporateUsers(_item, _queryText, _itemText) {
      const searchText = _queryText.toLowerCase();

      const fullName = _item.fullName.toLowerCase();
      const phone = _item.phone.toLowerCase();
      const email = _item.email.toLowerCase();

      return (
        fullName.indexOf(searchText) > -1 ||
        phone.indexOf(searchText) > -1 ||
        email.indexOf(searchText) > -1
      );
    },
    handleUserStatus(user, corporate_id) {
      if (
        user.corporate_ids &&
        user.corporate_ids.length > 0 &&
        user.corporate_ids[0].corporate_id === corporate_id &&
        user.corporate_ids[0].status.toString() !== "0"
      ) {
        return this.$t("Active");
      } else if (
        user.corporate_ids &&
        user.corporate_ids.length > 0 &&
        user.corporate_ids[0].corporate_id === corporate_id &&
        user.corporate_ids[0].status.toString() !== "1"
      ) {
        return this.$t("Waiting for approval");
      } else if (
        user.old_corporate_ids.toString().includes(corporate_id.toString())
      ) {
        return this.$t("Inactive");
      }
    },
    handleErrorCauseCheckUser({ trip }) {
      let cause = {
        cause: { tripID: trip.unique_id, properCause: "ERROR_CHECKUSER" },
      };
      if (trip.is_trip_completed && !trip.payment_status) {
        cause.cause.properCause = "VIAJE_PENDIENTE_POR_PAGO";
        return cause;
      }
      if (!trip.is_trip_completed && !trip.payment_status) {
        cause.cause.properCause = "VIAJE_EN_CURSO";
        return cause;
      }
      if (trip.is_trip_completed && !trip.is_provider_rated) {
        cause.cause.properCause = "FALTA_CALIFICACION_VIAJE";
        return cause;
      }
    },
    async getCorporateUsersSearch() {
      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_URL}/all_users`
        );
        if (data) {
          this.corporateUsersSearch = data;
        }
      } catch (error) {
        this.$dialog.notify.error("Error getting corporate users search");
      }
    },
    async validatePromoCode(event) {
      try {
        if (event) event.preventDefault();
        this.loading_promo = true;
        const response = await axios.post(
          `${process.env.VUE_APP_STAGING}/apply_promo_code`,
          {
            promocode: this.createTripForm.promo_code,
            user_id: this.createTripForm.userID,
            token: this.computedCurrentCorporateUser.token,
            city_id: this.city_id,
            country_id: this.country_id,
            service_type_id: this.createTripForm.serviceType,
            pickup_latitude: this.stateOriginAddressMap.geometry.location.lat(),
            pickup_longitude:
              this.stateOriginAddressMap.geometry.location.lng(),
            destination_latitude:
              this.stateDestinationAddressMap.geometry.location.lat(),
            destination_longitude:
              this.stateDestinationAddressMap.geometry.location.lng(),
            is_promo_code_preapplied: false,
          }
        );
        if (response?.data?.success) {
          const res_estimated = await axios.post(
            `${process.env.VUE_APP_STAGING}/get-farest-estimated-for-all-v2`,
            {
              user_id: this.createTripForm.userID,
              token: this.computedCurrentCorporateUser.token,
              pickup_latitude: `${this.stateOriginAddressMap.geometry.location.lat()}`,
              pickup_longitude: `${this.stateOriginAddressMap.geometry.location.lng()}`,
              destination_latitude: `${this.stateDestinationAddressMap.geometry.location.lat()}`,
              destination_longitude: `${this.stateDestinationAddressMap.geometry.location.lng()}`,
              distance: parseFloat(this.distance),
              time: parseFloat(this.duration),
              service_type_id: [this.createTripForm.serviceType],
              timezone: this.computedTimezone,
              promo_id: response.data.promo_id,
              surge_multiplier: this.surgeMultiplier,
              is_min_fare_surge: this.isSurgeHours,
            }
          );
          if (res_estimated.data?.success) {
            if (res_estimated.data?.estimated_fare?.[0]?.is_promo_apply) {
              this.$dialog.notify.success(
                this.$t("Promo code applied successfully")
              );
              this.demoQuote["TOTAL"] =
                "$" + res_estimated.data.estimated_fare[0].estimated_fare;
              this.estimatedFare =
                res_estimated.data.estimated_fare[0].estimated_fare;
              this.demoQuote["total_without_promo"] =
                "$" +
                res_estimated.data.estimated_fare[0]
                  .estimated_fare_before_promo;
              this.isPromoCodeValid = true;
              this.promo_id = response.data.promo_id;
            } else {
              this.$dialog.notify.error(
                this.$t("The promo code has expired or is invalid")
              );
            }
          } else {
            this.$dialog.notify.error(
              this.$t("Error getting estimated fare with promo code")
            );
          }
        } else {
          this.$dialog.notify.error(
            this.$t("The promo code has expired or is invalid")
          );
        }
      } finally {
        this.loading_promo = false;
      }
    },
    resetPromoCode() {
      this.demoQuote["TOTAL"] = this.demoQuote["total_without_promo"];
      this.demoQuote["total_without_promo"] = null;
      this.isPromoCodeValid = false;
      this.promo_id = null;
      this.createTripForm.promo_code = "";
      this.estimatedFare = this.estimatedFareWithoutPromo;
    },
    handlePromoCodeClick() {
      if (this.isPromoCodeValid) {
        this.resetPromoCode();
      } else if (!this.loading_promo) {
        this.validatePromoCode();
      }
    },
    downloadInvoice() {
      this.$emit("downloadQuotePDF");
    },
  },
  created() {
    let plusOne = this.dateTimePickerProps.datetime;
    plusOne.setMinutes(plusOne.getMinutes() + this.pre_time);
    this.dateTimePickerProps.datetime = plusOne;
    this.scheduleDate = plusOne;
    // this.dateTimePickerProps.timeProps.min = plusOne;
    let today = new Date();
    this.dateTimePickerProps.dateProps.min = this.$moment(today)
      .add(-4, "hours")
      .toISOString();
    this.dateTimePickerProps.dateProps.max = this.$moment(today)
      .add(-4, "hours")
      .add(15, "days")
      .toISOString();
    this.resetForm();
    this.getCorporateUsersSearch();
    this.meNotLoading();
    // console.info(this.errorCode['ERROR_CODE_NOT_GET_YOUR_DETAIL'])
    // console.info(this.handleErrorCoder(this.errorCode, 995))
  },
  watch: {
    "createTripForm.originAddress": function (newVal) {
      if (!newVal) {
        this.createTripForm.originAddress = null;
        this.createTripForm.originAddressMap = null;
        this.setOrigin(null);
      }
    },
    stateOriginAddressMap: async function () {
      if (this.stateOriginAddressMap && this.stateOriginAddressMap.geometry) {
        this.createTripForm.originAddress =
          this.stateOriginAddressMap.formatted_address;
        this.createTripForm.serviceType = null;
        await this.getServiceType(this.stateOriginAddressMap.geometry.location);
      }
    },
    "createTripForm.destinationAddress": function (newVal) {
      if (!newVal) {
        this.createTripForm.destinationAddress = null;
        this.createTripForm.destinationAddressMap = null;
        this.setDestination(null);
        // this.createTripForm.serviceType = null;
        // this.createTripForm.serviceTypeOptions = [];
      }
    },
    stateDestinationAddressMap: function () {
      if (
        this.stateDestinationAddressMap &&
        this.stateDestinationAddressMap.geometry
      ) {
        this.createTripForm.destinationAddress =
          this.stateDestinationAddressMap.formatted_address;
      }
    },
    "createTripForm.userType": function () {
      if (this.createTripForm.userType === "Corporate") {
        this.getCorporateUsers();
      }
    },
    "createTripForm.serviceType": function (newVal) {
      //recibir conductores cercanos según la flota y ubicacion
      if (newVal) {
        this.getServiceTypeDrivers();
      } else {
        this.setServiceTypeOrigin(null);
      }
    },
    "dateTimePickerProps.datetime": function (newVal) {
      if (newVal && newVal < this.scheduleDate) {
        this.$dialog.notify.warning(
          this.$t(
            "You've selected an invalid hour, previous to current time. Please select a different hour"
          )
        );
        let plusOne = new Date();
        plusOne.setMinutes(plusOne.getMinutes() + this.pre_time);
        this.dateTimePickerProps.datetime = plusOne;
        this.scheduleDate = plusOne;
      } else if (!newVal) {
        let plusOne = new Date();
        plusOne.setMinutes(plusOne.getMinutes() + this.pre_time);
        this.dateTimePickerProps.datetime = plusOne;
        this.scheduleDate = plusOne;
      }
    },
    "createTripForm.userFirstName": function (newVal) {
      this.createTripForm.userFirstName = this.capitalizeFirstLetter(newVal);
    },
    "createTripForm.userLastName": function (newVal) {
      this.createTripForm.userLastName = this.capitalizeFirstLetter(newVal);
    },
    computedPhoneNotCorporate: async function (_newVal, _oldVal) {
      if (
        this.computedPhoneNotCorporate &&
        this.computedPhoneNotCorporate.length === 10
      ) {
        try {
          this.loadingUserSearch = true;
          let params = {
            phone:
              this.createTripForm.phonePrefix.slice(1) +
              this.createTripForm.phone,
          };
          const { data } = await axios.get(
            `${process.env.VUE_APP_API_URL}/get_user_details`,
            { params: params }
          );
          console.log(data);
          //si el usuario existe en la base de datos de Ridery
          if (data.success) {
            this.$dialog.notify.success(this.$t("user_found_db_ridery"));
            this.userFoundInRideryDB = true;
            //Se verifica que el usuario no este en la nomina del panel
            let found = this.corporateUsersSearch.users.find((user) => {
              return (
                user.phone ===
                this.createTripForm.phonePrefix.slice(1) +
                  this.createTripForm.phone
              );
            });
            // Si existe en la nomina del corporativo
            if (found) {
              let status = this.handleUserStatus(
                found,
                this.corporateUsersSearch.corporate_id
              );
              //si esta activo se devuelve a corporate users en el formulario
              if (status === this.$t("Active")) {
                this.$dialog.notify.warning(
                  `El usuario correspondiente al número de teléfono ${this.createTripForm.phonePrefix}-${this.createTripForm.phone} se encuentra activo en el panel, por favor crear viaje como corporativo`
                );
                this.createTripForm.userType = "Corporate";
                this.createTripForm.phone = "";
                this.createTripForm.phonePrefix = "";
                this.createTripForm.userID = "";
                this.notCorporateDisabled = true;
              }
              if (status === this.$t("Waiting for approval")) {
                this.$dialog.notify.warning(
                  `El usuario correspondiente al número de teléfono ${this.createTripForm.phonePrefix}-${this.createTripForm.phone} se encuentra esperando aprobación en el panel, por favor sugerir al usuario aceptar invitación al panel corporativo desde la app móvil de Ridery`
                );
                //Si existe en la nomina esperando apobacion, hay que proteger los campos para evitar duplicados, se devuelve a users
                this.notCorporateDisabled = true;
                this.$router.push({
                  name: "users",
                  query: { waiting_approval_trip: 1 },
                });
              }
              if (status === this.$t("Inactive")) {
                this.$dialog.notify.warning(
                  this.$t("Usuario se encuentra inactivo en el panel")
                );
                this.notCorporateDisabled = true;
                const { user } = data;
                this.createTripForm.userFirstName = user.first_name;
                this.createTripForm.userLastName = user.last_name;
                if (user.email) {
                  this.createTripForm.email = user.email;
                  this.notCorporateDisabledEmail = true;
                } else {
                  this.createTripForm.email = "";
                  this.notCorporateDisabledEmail = false;
                }
              }
            }
            //si no esta en la nomina, pero si esta en la base de datos, se llenan los campos con los datos del user y se inhabilita la edicion
            if (!found) {
              this.$dialog.notify.success(
                this.$t("user_found_db_ridery_filling")
              );
              this.notCorporateDisabled = true;
              const { user } = data;
              this.createTripForm.userFirstName = user.first_name;
              this.createTripForm.userLastName = user.last_name;
              if (user.email) {
                this.createTripForm.email = user.email;
                this.notCorporateDisabledEmail = true;
              } else {
                this.createTripForm.email = "";
                this.notCorporateDisabledEmail = false;
              }
              this.userFoundInRideryDB = true;
            }
          }
          //usuario no encontrado en la base de datos de ridery
          if (!data.success) {
            console.log("user not found");
            this.createTripForm.email = "";
            this.createTripForm.userFirstName = "";
            this.createTripForm.userLastName = "";
            this.notCorporateDisabled = false;
            this.notCorporateDisabledEmail = false;
            this.userFoundInRideryDB = false;
          }
        } catch (error) {
          console.log(error);
        } finally {
          this.loadingUserSearch = false;
        }
      }
    },
  },
  computed: {
    ...mapState([
      "stateOriginAddressMap",
      "stateDestinationAddressMap",
      "stateServiceTypeOrigin",
    ]),
    ...mapState("auth", [
      "countryName",
      "countryPhoneCode",
      "_id",
      "pre_time",
      "wallet",
      "unique_id",
      "is_used_google_maps_service",
    ]),

    handleColorClose() {
      return this.$vuetify.theme.dark
        ? "color: #ffffff !important;"
        : "color: #3a3541 !important;";
    },
    google: gmapApi,
    computedDateQuote() {
      if (this.createTripForm.tripType === "Travel now") {
        return new Date();
      } else {
        return this.dateTimePickerProps.datetime;
      }
    },
    computedDateQuoteFareEstimate() {
      if (this.createTripForm.tripType === "Travel now") {
        let date = new Date();
        this.$moment(date).add(-4, "hours").toISOString();
        return this.$moment(date).add(-4, "hours").toISOString();
      } else {
        let date = this.dateTimePickerProps.datetime;
        this.$moment(date).add(-4, "hours").toISOString();
        return this.$moment(date).add(-4, "hours").toISOString();
      }
    },
    computedTimezone() {
      // let today = new Date()
      // return this.$moment.utc(today).valueOf();
      return "UTC";
    },
    computedDisabledCreateTrip() {
      if (this.step === 2) {
        if (
          this.createTripForm.userType === "Corporate" &&
          !this.createTripForm.isCorporateWallet
        ) {
          if (
            this.corporateWalletUser < parseFloat(this.estimatedFare) &&
            !this.overdraftUser
          ) {
            return true;
          }
          if (
            this.corporateWalletUser < parseFloat(this.estimatedFare) &&
            this.overdraftUser &&
            parseFloat(this.estimatedFare) > this.wallet
          ) {
            return true;
          }
          if (this.loading_promo) {
            return true;
          }
        } else {
          if (parseFloat(this.estimatedFare) > this.wallet) {
            return true;
          }
        }
        return false;
      }
    },
    computedDisabledCreateTripNote() {
      if (this.step === 2) {
        if (
          this.createTripForm.userType === "Corporate" &&
          !this.createTripForm.isCorporateWallet
        ) {
          if (
            this.corporateWalletUser < parseFloat(this.estimatedFare) &&
            !this.overdraftUser
          ) {
            return `el usuario <span class="font-weight-bold text-decoration-underline">no</span> posee saldo corporativo suficiente ($${parseFloat(
              this.corporateWalletUser
            ).toFixed(
              2
            )}) <span class="font-weight-bold text-decoration-underline">ni</span> posee sobregiro`;
          }
          if (
            this.corporateWalletUser < parseFloat(this.estimatedFare) &&
            this.overdraftUser &&
            parseFloat(this.estimatedFare) > this.wallet
          ) {
            return `el usuario <span class="font-weight-bold text-decoration-underline">no</span> posee saldo corporativo suficiente ($${parseFloat(
              this.corporateWalletUser
            ).toFixed(
              2
            )}), posee sobregiro, pero el panel corporativo <span class="font-weight-bold text-decoration-underline">no</span> dispone de suficiente balance para crear el viaje ($${
              this.wallet
            })`;
          }
        } else {
          if (parseFloat(this.estimatedFare) > this.wallet) {
            return (
              `El panel corporativo <span class="font-weight-bold text-decoration-underline">no</span> dispone de suficiente balance para crear el viaje` +
              " " +
              `($${this.wallet})`
            );
          }
        }
        return false;
      }
    },
    computedCurrentCorporateUser() {
      return this.createTripForm.corporateUsersSearch.find(
        (user) => user._id === this.createTripForm.userID
      );
    },
    computedPhoneNotCorporate() {
      return (
        this.createTripForm.phonePrefix &&
        this.createTripForm.phone &&
        this.createTripForm.phonePrefix.slice(1) + this.createTripForm.phone
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.fontUrbanist {
  font-family: "Urbanist", sans-serif !important;
}
.shipment-summary {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
}
.border-bottom-gray {
  border-bottom: 1px solid #898989;
}
</style>
